import * as React from 'react';
import CookieConsent from '../components/cookie-consent';
import AdSense from 'react-adsense';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from './seo.js';

import {
  header,
  content,
  consent,
  container,
  footer,
} from '../styles/layout.module.css';
import '../styles/global.css';

export default function Layout({
  children,
  title = false,
  description = false,
  image = false,
  path = false,
  keywords = false, // this line added for post kwds
}) {
  const data = useStaticQuery(graphql`
    query GetSiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const meta = data?.site?.siteMetadata ?? {};

  return (
    <>
      <Seo
        title={title}
        description={description}
        image={image}
        path={path}
        keywords={keywords} // added for post kwds
      />
      <header className={header}>
        <Link to="/">{meta.title}</Link>
        <nav>
          <Link to="/about">About</Link>
        </nav>
      </header>
      <main className={content}>
        {children}
        <AdSense.Google
          client="ca-pub-9761877519039349"
          slot="1377586348"
          style={{ display: 'block' }}
          layout="in-article"
          format="fluid"
        />
      </main>
      <div className={consent}>
        <CookieConsent />
      </div>
      <div className={container}>
        <div className={footer}>
          <p>
            &copy;{new Date().getFullYear()}
            <Link to="/">AllCorvetteModels</Link>
          </p>
        </div>
        <div className={footer}>
          <p>
            <StaticImage
              src="../images/positivessl_trust_seal_md_167x42.png"
              alt="positivessl trust seal"
              placeholder="dominantColor"
            />
          </p>
        </div>
        <div className={footer}>
          <p>
            <Link to="/privacy">Privacy</Link>
          </p>
        </div>
      </div>
    </>
  );
}
